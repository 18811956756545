.apple-btn {
    margin-top: 20px;
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: center;
}
/*
This makes it the same width as the registration btn. Does not work with google btn
.apple-btn div {
    max-width: 100% !important;
    width: 100% !important;
}
*/
.apple-btn div {
    width: 375px !important;
}

@media only screen and (max-width: 900px) {
    .apple-btn div {
        max-width: 310px !important;
        width: 310px !important;
        min-width: 310px !important;
    }
  }

.google-btn {
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: center;
}