.password-checklist {
    font-family: Sora;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.12px;
    text-align: left;
    margin-top: 20px !important;
}

.regpolicytxt a {
    font-family: Sora;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.16px;
    text-align: left;
    color: #1542B9;
    text-decoration: none;
}
