body {
  background-color: #FAFCFC;
}

.my-container {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.login-container {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 480px;
}

@media only screen and  (max-width: 900px) {
  .my-container {
    max-width: 100%;
  }
  .login-container {
    max-width: 100%;
  }
}

@media only screen and  (min-width: 900px) and (max-width: 1280px) {

  .my-container {
      max-width: 900px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


span[role="alert"] {
  color: #990000;
  display: flex;
  margin: 10px 10px 0;
  font-weight: 600;
  font-size: 0.8em;
}

.nofocus fieldset  {
	border-color: transparent !important;
}