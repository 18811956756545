body {
    font-family: Arial, sans-serif;
    background-color: #f9fafc;
    margin: 0;
    padding: 0;
}

.stepper-container {
    display: flex;
    justify-content: space-around;
    margin: auto;
    max-width: 1320px;
    margin-top: 50px;
    position: relative;
    z-index: 1;
}
.stepper-container .bg {
    position: absolute;
    top: 16px;
    left: 0;
    width: 68%;
    padding: 0 0 0 60px;
    overflow: hidden;
}

.stepper-container.step-3 .bg {
    width: 55% !important;
}

.stepper-container .bg .inner,
.stepper-container .bg .inner-active {
    width: 10000%;
}
.stepper-container .bg .inner-active.step-1 {
    display: none;
    width: 0%;
}
.stepper-container .bg .inner-active.step-2 {
    width: 35%;
}
.stepper-container .bg .inner-active.step-3 {
    width: 100%;
}
.stepper-container .bg .line {
    display: block;
    float: left;
    width: 6px;
    height: 4px;
    background-color: rgba(43, 54, 116, .6);
    border-radius: 50%;
    margin-right: 6px;
}
.stepper-container .bg .inner-active {
    position: absolute;
    top: 0;
    z-index: 21;
}
.stepper-container .bg .inner-active .line {
    display: block;
    float: left;
    width: 6px;
    height: 4px;
    background-color: rgba(98, 194, 117, 1);
    border-radius: 50%;
    margin-right: 6px;
}

.step {
    background: transparent;
    padding: 0px;
    text-align: center;
    width: 27.5%;
    position: relative;
    margin: 0 10px ;
    display: flex;
    flex-direction: column;
}
.step .step-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.step.current {
    width: 45%
}

.step-number-container {
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 10px;
    z-index: 20;
}

.step-number {
    background: #7E85AB;
    color: #ffffff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateX(-50%);
    font-weight: bold;
    font-family: 'Sora';
    font-size: 18px;
    font-weight: 700;
    line-height: 22.68px;
    text-align: left;
    box-shadow: 0 0 0 5px #FAFCFC;
}

.step.completed .step-number {
    background: rgba(98, 194, 117, 1) !important;
}

.step-content .icon {
    margin-bottom: 15px;
}

.step-content h2 {
    font-size: 18px;
    color: #333333;
    margin-bottom: 10px;
}

.step-content p {
    font-size: 14px;
    color: #666666;
    margin-bottom: 20px;
}

button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
}

button:hover {
    background-color: #45a049;
}

@media (max-width: 640px) {
    .stepper-container {
        flex-direction: column;
    }
    .stepper-container .bg {
        display: none;
    }
    .step {
        width: 100% !important;
        margin: 15px 0;
    }

    .step-number-container {
        top: 15px;
        left: auto;
        right: 15px
    }
    .step-number-container .step-number {
        left: auto;
        right: 0;
        transform: none;
    }
}
